import React from 'react';
import { useTour } from '@reactour/tour';
import { createUseStyles } from 'react-jss';
import SusyDarkMode from './assets/img/wanna/susy/susy-dark-mode.png';
import Susy2 from './assets/img/wanna/susy/susy2.png';
import Button from './components/bootstrap/Button';

/**
 * Steps style
 */
export const styles = {
	/**
	 * Overlay style
	 * @param base
	 */
	maskWrapper: (base: any) => ({ ...base }),
	maskArea: (base: any) => ({
		...base,
		rx: 'var(--bs-border-radius)',
	}),
	highlightedArea: (base: any) => ({ ...base }),
	badge: (base: any) => ({ ...base }),
	popover: (base: any) => ({
		...base,
		boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)',
		backgroundColor: 'var(--bs-body-bg)',
		color: 'var(--bs-body-color)',
		borderRadius: 'var(--bs-border-radius)',
	}),
};

/**
 * Image Styles
 * @type {(data?: {theme?: DefaultTheme}) => Classes<"image">}
 */
const useStyles = createUseStyles({
	image: {
		maxHeight: '150px',
		objectFit: 'contain',
	},
});

/**
 * Prev & Next buttons
 * @returns {JSX.Element}
 * @constructor
 */
interface startStep {
	startStep?: boolean;
	endStep?: boolean;
}
const TourNavigation = ({startStep, endStep}:startStep) => {
	const { currentStep, setCurrentStep } = useTour();
	return (
		<div className='col-12 mt-3'>
			<div className='row'>
			<div className='col'>
					{!startStep && (
						<Button
							icon='ArrowBackIos'
							color='info'
							isLink
							onClick={() => setCurrentStep(currentStep - 1)}>
							Prev
						</Button>
					)}
				</div>
				<div className='col-auto'>
					{!endStep && (<Button
						icon='ArrowForwardIos'
						color='danger'
						isLight
						onClick={() => setCurrentStep(currentStep + 1)}>
						Next
					</Button>)}
				</div>
			</div>
		</div>
	);
};

/**
 * Selector
 * @param name
 * @returns {`[data-tour='${string}']`}
 */
const getTargetName = (name: string): `[data-tour='${string}']` => {
	return `[data-tour='${name}']`;
};

interface TourData {
	title: string;
	text: string;
	endStep?: boolean;
	startStep?: boolean;
}

const TourStep = ({ title, text, endStep, startStep }: TourData) => {
	return (
		<div className='row'>
			<div className='col-auto d-flex align-items-center'>
				<div>
					<p className='lead'>{title}</p>
					<p>{text}</p>
				</div>
			</div>
			<TourNavigation startStep={startStep} endStep={endStep} />
		</div>
	);
};

/**
 * Tour Steps
 */
const steps = [
	/**
	 * Dark Mode
	 * @step 0
	 */
	{
		page: "dashboard",
		selector: getTargetName('startTourButton'),
		content: () => <TourStep title={"Welcome!"} text={"Welcome to aiosbot!"} startStep />,
	},
	{
		selector: getTargetName('startTourButton'),
		content: () => <TourStep title={"Help Tour"} text={"If you need any help, click here to get guided through the current page."} />,
	},
	{
		selector: getTargetName('BotSelectionButton'),
		content: () => <TourStep title={"Add Bot"} text={"If you don't want to add a bot yourself, you can gain rights from another user and select his/her bot here. If you want to create your own bot, you should start by adding one on the left side."} endStep />,
	},
	/**
	 * Add Bot
	 * @step 3
	 */
	{
		page: "addBot",
		selector: getTargetName('addBot'),
		content: () => <TourStep title={"Add Bot"} text={"To get startet with aiosbot you need to add a bot to our system."} startStep />,
	},
	{
		selector: getTargetName('addBotBotName'),
		content: () => <TourStep title={"Bot Name"} text={"Enter any name to identify your bot. You do not need to enter your Twitch or Discord name. This name is just for you and users who have rights on your bot will see it."} />,
	},
	{
		selector: getTargetName('addBotSave'),
		content: () => <TourStep title={"Add"} text={"After clicking on \"Add Bot\" your bot will be registered in our system."} />,
	},
	{
		selector: getTargetName('BotSelectionButton'),
		content: () => <TourStep title={"Select Bot"} text={"After you added your bot, you can select it here."} endStep />,
	},

	/** Profile
	 * @step 7
	 */
	{
		page: "profile-settings",
		selector: getTargetName('changePassword'),
		content: () => <TourStep title={"Change password"} text={"Here you can change your password."} startStep endStep />,
	},

	/** Authentification
	 * @step 8
	 */
	{
		page: "twitch-authentification",
		selector: getTargetName('twauthname'),
		content: () => <TourStep title={"Scope name"} text={"Name of the scope."} startStep />,
	},
	{
		page: "twitch-authentification",
		selector: getTargetName('twauthscope'),
		content: () => <TourStep title={"Scope identifier"} text={"Twitch identifier of the scope. If you are missing a scope for a specific action, this identifier will be shown in a notification."} />,
	},
	{
		page: "twitch-authentification",
		selector: getTargetName('twauthcheck'),
		content: () => <TourStep title={"Active?"} text={"Select or deselect the scope to give us access to it."} />,
	},
	{
		page: "twitch-authentification",
		selector: getTargetName('twauthinfo'),
		content: () => <TourStep title={"More Information"} text={"If you want more information about the selected scope, click here..."} />,
	},
	{
		page: "twitch-authentification",
		selector: getTargetName('twauthsave'),
		content: () => <TourStep title={"Save"} text={"You can save your settings here. You will be redirected to the Twitch authorization page. Follow the instructions there."} endStep />,
	},

	/** AddBotFinish
	 * @step 8
	 */
	{
		page: "addBotFinish",
		selector: getTargetName('BotSelectionButton'),
		content: () => <TourStep title={"Your bot has been added"} text={"You can now always select your bot here. You will also find other bots you got permissions for here."} startStep endStep />,
	},
];

export default steps;
