// @ts-nocheck
import React, { useContext } from 'react';
import SubHeader, {
    SubHeaderLeft,
    SubHeaderRight,
    SubheaderSeparator,
} from '../layout/SubHeader/SubHeader';
import Popovers from '../components/bootstrap/Popovers';
import { useTour, goToStep } from '@reactour/tour';
import Button from '../components/bootstrap/Button';
import useDarkMode from '../hooks/useDarkMode';
import Breadcrumb from '../components/bootstrap/Breadcrumb';
import steps from '../steps';

interface bcobj{
    title: string;
    to: string;
}

interface tourGuide {
    page: string;
    bclist: array<bcobj>
}

const TourGuide = ({ page, bclist }: tourGuide) => {
    const { darkModeStatus, setDarkModeStatus } = useDarkMode();
    const { setIsOpen, setCurrentStep } = useTour();
    const startTour = () => {
        let index = steps.findIndex(s => s.page === page);
        setCurrentStep(index);
        setIsOpen(true)
    }
    if (sessionStorage.getItem('firstTime')) {
        setCurrentStep(0);
        setIsOpen(true);
        sessionStorage.removeItem('firstTime');
    }
    const styledBtn: IButtonProps = {
        color: darkModeStatus ? 'dark' : 'light',
        hoverShadow: 'default',
        isLight: !darkModeStatus,
        size: 'lg',
    };

    return (
        <SubHeader>
            <SubHeaderLeft>
                <Breadcrumb
                    list={bclist}
                />
            </SubHeaderLeft>
            <SubHeaderRight>
                <div className='col-auto position-relative'>
                    <Popovers trigger='hover' desc='Help Tour'>
                        <Button
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...styledBtn}
                            data-tour="startTourButton"
                            icon='Tour'
                            onClick={startTour}
                            aria-label='Help Tour'
                        />
                    </Popovers>
                </div>
            </SubHeaderRight>
        </SubHeader>
    );
};

export default TourGuide;
