import React, { lazy } from 'react';
import { dashboardPagesMenu, authPagesMenu, pageLayoutTypesPagesMenu, AddBotMenu, TwitchMenu, BotMenu, TwitchInactiveMenu, DiscordInactiveMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';
import ProfileSettingsPage from '../pages/presentation/user/profile-settings';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const NEWBOT = {
	NEWBOT: lazy(() => import('../pages/presentation/addbot/AddBotPage')),
	NEWTWITCH: lazy(() => import('../pages/presentation/twitch/addTwitch/add')),
	NEWDISCORD: lazy(() => import('../pages/presentation/discord/addDiscord/add')),
}

const TWITCH = {
	DASHBOARD: lazy(() => import('../pages/presentation/twitch/dashboard')),
	COMMANDSOVERVIEW: lazy(() => import('../pages/presentation/twitch/commands/overview')),
	COMMANDSADD: lazy(() => import('../pages/presentation/twitch/commands/add')),
	COMMANDALIASADD: lazy(() => import('../pages/presentation/twitch/commands/addalias')),
	REACTIONSADD: lazy(() => import('../pages/presentation/twitch/reactions/add')),
	REACTIONSOVERVIEW: lazy(() => import('../pages/presentation/twitch/reactions/overview')),
	AUTOACTIONSADD: lazy(() => import('../pages/presentation/twitch/autoactions/add')),
	AUTOACTIONSOVERVIEW: lazy(() => import('../pages/presentation/twitch/autoactions/overview')),
	EVENTACTIONSADD: lazy(() => import('../pages/presentation/twitch/eventactions/add')),
	EVENTACTIONSOVERVIEW: lazy(() => import('../pages/presentation/twitch/eventactions/overview')),
	AUTHENTIFICATION: lazy(() => import('../pages/presentation/twitch/misc/authentification')),
	AUTHCALLBACK: lazy(() => import('../pages/presentation/twitch/misc/auth/callback')),
	AUTOBAN: lazy(() => import('../pages/presentation/twitch/misc/autoban')),
	URLFILTER: lazy(() => import('../pages/presentation/twitch/misc/urlfilter')),
	FOLLOWBOTS: lazy(() => import('../pages/presentation/twitch/misc/followbots'))
}

const DISCORD = {

}

const BOTMENU = {
	COUNTVARS: lazy(() => import('../pages/presentation/botmenu/countvars')),
	SETTINGS: lazy(() => import('../pages/presentation/botmenu/settings'))
}

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: authPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: authPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: authPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},


	/** *************************************************** */
	
	/**
	 * Add Bot
	 */
	{
		path: AddBotMenu.addBot.path,
		element: <NEWBOT.NEWBOT />,
	},
	{
		path: TwitchInactiveMenu.addTwitch.path,
		element: <NEWBOT.NEWTWITCH />,
	},
	{
		path: DiscordInactiveMenu.addDiscord.path,
		element: <NEWBOT.NEWDISCORD />,
	},


	/**
	 * TWITCH
	 */
	{
		path: TwitchMenu.twdashboard.path,
		element: <TWITCH.DASHBOARD />
	},
	{
		path: TwitchMenu.twcommands.subMenu.twcommandadd.path,
		element: <TWITCH.COMMANDSADD />
	},
	{
		path: TwitchMenu.twcommands.subMenu.twcommandoverview.path,
		element: <TWITCH.COMMANDSOVERVIEW />
	},
	{
		path: TwitchMenu.twcommands.subMenu.twcommandalias.path,
		element: <TWITCH.COMMANDALIASADD />
	},
	{
		path: TwitchMenu.twreactions.subMenu.twreactionadd.path,
		element: <TWITCH.REACTIONSADD />
	},
	{
		path: TwitchMenu.twreactions.subMenu.twreactionoverview.path,
		element: <TWITCH.REACTIONSOVERVIEW />
	},
	{
		path: TwitchMenu.twautoaction.subMenu.twautoactionnadd.path,
		element: <TWITCH.AUTOACTIONSADD />
	},
	{
		path: TwitchMenu.twautoaction.subMenu.twautoactionoverview.path,
		element: <TWITCH.AUTOACTIONSOVERVIEW />
	},
	{
		path: TwitchMenu.tweventactions.subMenu.tweventactionsadd.path,
		element: <TWITCH.EVENTACTIONSADD />
	},
	{
		path: TwitchMenu.tweventactions.subMenu.tweventactionsoverview.path,
		element: <TWITCH.EVENTACTIONSOVERVIEW />
	},
	/*{
		path: TwitchMenu.twauthentification.path,
		element: <TWITCH.AUTHENTIFICATION />
	},*/
	{
		path: "twitch/auth/cb",
		element: <TWITCH.AUTHCALLBACK />
	},
	{
		path: TwitchMenu.twSecurity.subMenu.twautoban.path,
		element: <TWITCH.AUTOBAN />
	},
	{
		path: TwitchMenu.twSecurity.subMenu.twlinkfilter.path,
		element: <TWITCH.URLFILTER />
	},
	/*{
		path: TwitchMenu.twSecurity.subMenu.followbots.path,
		element: <TWITCH.FOLLOWBOTS />
	},*/
	{
		path: BotMenu.cv.path,
		element: <BOTMENU.COUNTVARS />
	},
	{
		path: BotMenu.settings.path,
		element: <BOTMENU.SETTINGS />
	},


	{
		path: "profile-settings",
		element: <ProfileSettingsPage />,
	},




	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: pageLayoutTypesPagesMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	},
];
const contents = [...presentation];

export default contents;
