import React, { ReactNode, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { dashboardPagesMenu, authPagesMenu, pageLayoutTypesPagesMenu, AddBotMenu, TwitchMenu, DiscordMenu, HelpCenter, BotMenu, TwitchInactiveMenu, DiscordInactiveMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Popovers from '../../../components/bootstrap/Popovers';
import AuthContext from '../../../contexts/authContext';
import Card, { CardBody, CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import { Link } from 'react-router-dom';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { userData, setUser } = useContext(AuthContext);

	const [botSelected, setBotSelected] = useState(false);
	const [twitchActive, setTwitchActive] = useState(false);
	const [discordActive, setDiscordActive] = useState(false);

	useEffect(() => {
		if (userData.bot) {
			setBotSelected(true);
			if (userData.bot.twitchactivated) {
				setTwitchActive(true);
			} else {
				setTwitchActive(false);
			}
			if (userData.bot.discordactivated) {
				setDiscordActive(true);
			} else {
				setDiscordActive(false);
			}
		}
		else {
			setBotSelected(false);
			setTwitchActive(false);
			setDiscordActive(false);
		}
	}, [userData])

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
				<NavigationLine />
				{!botSelected && (
					<>
						<Navigation menu={AddBotMenu} id='aside-newbot-pages' />
						<NavigationLine />

					</>
				)}

				{botSelected && (<>

					{twitchActive ? <><Navigation menu={TwitchMenu} id='aside-twitchmenu-pages' />
						<NavigationLine /></> : <><Navigation menu={TwitchInactiveMenu} id='aside-twitchmenu-pages' />
						<NavigationLine /></>}

					{discordActive ? <><Navigation menu={DiscordMenu} id='aside-discordmenu-pages' />
						<NavigationLine /></> : <><Navigation menu={DiscordInactiveMenu} id='aside-twitchmenu-pages' />
						<NavigationLine /></>}


					<Navigation menu={BotMenu} id='aside-botmenu-pages' />
					<NavigationLine />

					{/* <Navigation menu={authPagesMenu} id='aside-auth-pages' />
					<NavigationLine />
					<Navigation menu={pageLayoutTypesPagesMenu} id='aside-menu' />
					<NavigationLine /> */}
				</>)}

				{
					<>
						<Navigation menu={HelpCenter} id='aside-menu' /></>
				}
				<Card className='m-3 '>
					<CardHeader><CardTitle>Donate</CardTitle></CardHeader>
					<CardBody className='pt-0'>
						<p>
							{'Help aiosbot development' as ReactNode}
						</p>
						<Button
							color='info'
							isLight
							onClick={() => { window.open("https://ko-fi.com/aiosbot", "_blank") }}
							className='w-100'>
							Donate
						</Button>
					</CardBody>
				</Card>
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
