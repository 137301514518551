import React, { useLayoutEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Popovers from '../../../components/bootstrap/Popovers';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import LANG, { getLangWithKey, ILang } from '../../../lang';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import Spinner from '../../../components/bootstrap/Spinner';
import CommonHeaderRight from './CommonHeaderRight';
import AuthContext from '../../../contexts/authContext';

const DashboardHeader = () => {
	const { userData, setUser } = useContext(AuthContext);
	return (
		<Header>
			<HeaderLeft>
				<div className='row g-3'>
					<div className='col-auto'>
						Selected Bot: <div className='fw-bold'>{userData ? userData.bot ? userData.bot.name ? userData.bot.name : "None" : "None" : "None"}</div>
					</div>
				</div>
			</HeaderLeft>

			<CommonHeaderRight></CommonHeaderRight>
		</Header>
	);
};

export default DashboardHeader;
