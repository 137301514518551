// @ts-nocheck
import React, { FC, ReactNode, useContext, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTour } from '@reactour/tour';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import LANG, { getLangWithKey, ILang } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import AuthContext from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [notifications, setNotifications] = useState([]);
	const [notificationCount, setNotificationCount] = useState(0);
	const { userData, setUser } = useContext(AuthContext);
	const [bots, setBots] = useState([]);
	const navigate = useNavigate();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { setIsOpen } = useTour();
	useEffect(() => {
		if (userData.token) {
			fetch(`https://backend.aiosbot.com:8444/notifications`, {
				headers: {
					'Content-Type': 'application/json',
					authorization: userData.token
				}
			})
				.then((res) => res.json())
				.then((data) => {
					var current = new Date();
					let res = data.result;
					for (let i = 0; i < res.length; i++) {
						var minutes = Math.floor((current.getTime() - new Date(res[i].date).getTime()) / 1000 / 60);
						let ago = "now";
						if (minutes >= 5 && minutes < 60)
							ago = minutes + " min";
						if (minutes >= 60)
							ago = Math.floor((minutes / 60)) + " hours";
						if (minutes >= 60 * 24)
							ago = Math.floor(((minutes / 60) / 24)) + " days";
						res[i].ago = ago;
					}
					setNotifications(data.result);
					let count = 0;
					data.result.forEach(item => {
						if (item.read == 0)
							count++;
					})
					setNotificationCount(count);
				})
				.catch((err) => {
				});

			fetch(`https://backend.aiosbot.com:8445/getAllBots`, {
				headers: {
					'Content-Type': 'application/json',
					authorization: userData.token,
				}
			})
				.then((res) => res.json())
				.then((data) => {
					setBots(data);
				})
				.catch((err) => {
				});
		}
	}, [userData]);

	const selectBot = (id) => {
		fetch(`https://backend.aiosbot.com:8444/newBotIDToken`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: userData.token,
                botid: id
            }
        })
            .then((res) => res.json())
            .then((data) => {
				sessionStorage.setItem('token', data.authtoken);
				window.location.href = '/';
            })
            .catch((err) => {
            });
	}

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'
							aria-label='Toggle dark mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle fullscreen'
						/>
					</Popovers>
				</div>

				{/* Quick Panel */}
				<div className='col-auto' data-tour="BotSelectionButton">
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							{/* eslint-disable-next-line react/jsx-props-no-spreading */}
							<Button {...styledBtn} icon='Tune' aria-label='Bot select' />
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
							<div className='row g-0'>
								<div
									className={classNames(
										'col-12',
										'p-4',
										'd-flex justify-content-center',
										'fw-bold fs-5',
										'text-info',
										'border-bottom border-info',
										{
											'bg-l25-info': !darkModeStatus,
											'bg-lo25-info': darkModeStatus,
										},
									)}>
									Select bot
								</div>
								<DropdownItem isDivider></DropdownItem>
								<DropdownItem onClick={() => { selectBot(-1) }}>New Bot...</DropdownItem>
								<DropdownItem isDivider></DropdownItem>
								{
									bots.map((value, key) => (
										<div key={`bot${key}`}>
											<DropdownItem onClick={() => { selectBot(value.id) }}>{value.name}</DropdownItem>
											<DropdownItem isDivider></DropdownItem>
										</div>
									))
								}
							</div>
						</DropdownMenu>
					</Dropdown>
				</div>

				{/*	Notifications */}
				<div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setOffcanvasStatus(true)}
						aria-label='Notifications'
					/>
				</div>
				{afterChildren}
			</div>

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					{
						notifications.map((value, key) => (
							<Alert key={`notification${key}`} icon='ViewInAr' isOutline color='warning' isDismissible className='flex-nowrap'>
								<AlertHeading tag={"h5"}>{value.title}</AlertHeading>
								{value.text}
							</Alert>
						))
					}
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
