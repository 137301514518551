import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode';

export interface IBotProps {
	id: number;
	name: string;
	twitchactivated: boolean;
	discordactivated: boolean;
	premium: number;
	twitch: string;
	discord: string;
	banned: boolean;
	owner: number;
}

export interface IUserProps {
	id: number;
	username: string;
	mail: string;
	password: string;
	beta: number;
	src: string;
	token: string;
	bot?: IBotProps;
}
export interface jwtUser {
	activated: number;
	banned: number;
	beta: number;
	id: number;
	lastlogin: string;
	mail: string;
	password: string;
	registercode?: string;
	username: string;
}
export interface jwtBot {
	banned: number;
	discord: string;
	discordactivated: number;
	id: number;
	name: string;
	owner: number;
	premium: number;
	twitch: string;
	twitchactivated: number;
}
export interface jwtDecoded {
	iat: number;
	user: jwtUser;
	bot?: jwtBot;
}

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [token, setToken] = useState<string>(sessionStorage.getItem('token') || '');
	const [userData, setUserData] = useState<Partial<IUserProps>>({});

	useEffect(() => {
		sessionStorage.setItem('token', token);
	}, [token]);

	useEffect(() => {
		if (token !== '') {
			try {
				const request = new XMLHttpRequest();
				request.open('GET', `https://backend.aiosbot.com:8444/verify`, false);
				request.setRequestHeader('authorization', token);
				request.send(null);
				if (request.status != 200) {
					sessionStorage.clear();
					localStorage.clear();
					window.location.href = '/';
					return;
				}
				const decoded: jwtDecoded = jwtDecode(token);
				const user: IUserProps = {
					id: decoded.user.id,
					username: decoded.user.username,
					mail: decoded.user.mail,
					src: 'https://aiosbot.com/logo.png',
					password: decoded.user.password,
					beta: decoded.user.beta,
					token: token,
				};
				if (decoded.bot) {
					const bot: IBotProps = {
						id: decoded.bot.id,
						name: decoded.bot.name,
						discordactivated: decoded.bot.discordactivated == 1 ? true : false,
						twitchactivated: decoded.bot.twitchactivated == 1 ? true : false,
						twitch: decoded.bot.twitch ? decoded.bot.twitch : '',
						discord: decoded.bot.discord ? decoded.bot.discord : '',
						premium: decoded.bot.premium,
						banned: decoded.bot.banned == 1 ? true : false,
						owner: decoded.bot.owner,
					};
					user.bot = bot;
				}
				setUserData(user);
			} catch (e) {
				sessionStorage.clear();
				localStorage.clear();
				window.location.href = '/';
			}
		} else {
			setUserData({});
		}
	}, [token]);

	const value = useMemo(
		() => ({
			user: token,
			setUser: setToken,
			userData,
		}),
		[token, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
