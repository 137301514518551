import React, { useContext } from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { pageLayoutTypesPagesMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import Popovers from '../../../components/bootstrap/Popovers';
import CommonHeaderRight from './CommonHeaderRight';
import AuthContext from '../../../contexts/authContext';

const DefaultHeader = () => {
	const { userData, setUser } = useContext(AuthContext);

	return (
		<Header>
			<HeaderLeft>
				<div className='row g-3'>
					<div className='col-auto'>
						Selected Bot: <div className='fw-bold'>{userData ? userData.bot ? userData.bot.name ? userData.bot.name : "None" : "None" : "None"}</div>
					</div>
				</div>
			</HeaderLeft>

			<CommonHeaderRight></CommonHeaderRight>
		</Header>
	);
};

export default DefaultHeader;
