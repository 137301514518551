import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import md5 from 'md5';
import showNotification from '../../../components/extras/showNotification';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const { userData, setUser } = useContext(AuthContext);

	const navigate = useNavigate();
	const handleOnClick = () => (window.location.href = '/');

	const [dbUsername, setDbUsername] = useState('');

	const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			);
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (values.loginUsername && !validateEmail(values.loginUsername)) {
				errors.loginUsername = 'Enter valid email';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: true,
		onSubmit: (values) => {
			const request = new XMLHttpRequest();
			request.open(
				'GET',
				`https://backend.aiosbot.com:8444/login?mail=${values.loginUsername}&password=${md5(values.loginPassword)}`,
				false,
			);
			request.send(null);
			if (request.status == 200) {
				sessionStorage.setItem('token', JSON.parse(request.response).authtoken);
				//if (setUser) setUser(JSON.parse(request.response).authtoken);
				setTimeout(() => {
					handleOnClick();				
				}, 1500);
			} else {
				formik.setFieldError('loginPassword', 'Username and password do not match.');
				showNotification("Login failed", "Username and password do not match", "danger");
			}
		},
	});

	const registerFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			registerMail: '',
			registerUsername: '',
			registerPassword: '',
		},
		validate: (values) => {
			const errors: {
				registerMail?: string;
				registerUsername?: string;
				registerPassword?: string;
			} = {};

			if (!values.registerMail) {
				errors.registerMail = 'Required';
			}

			if (values.registerMail && !validateEmail(values.registerMail)) {
				errors.registerMail = 'Enter valid email';
			}

			if (!values.registerUsername) {
				errors.registerUsername = 'Required';
			}

			if (values.registerUsername && values.registerUsername.length < 3) {
				errors.registerUsername = 'Must be at least 3 characters';
			}

			if (!values.registerPassword) {
				errors.registerPassword = 'Required';
			}

			if (values.registerPassword && values.registerPassword.length < 5) {
				errors.registerPassword = 'Must be at least 5 characters';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			setIsLoading(true);
			fetch('https://backend.aiosbot.com:8444/register', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					mail: values.registerMail,
					username: values.registerUsername,
					password: md5(values.registerPassword),
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					setIsLoading(false);
					if (data.status == 403) {
						if (data.error == 'Mail already exists') {
							return registerFormik.setFieldError('registerMail', data.error);
						}
						if (data.error == 'Username already taken') {
							return registerFormik.setFieldError('registerUsername', data.error);
						}
					}
					if (data.status == 200) {
						showNotification("Registered", "Registration successfull", "success");
						//return handleOnClick();
						//registerFormik.setFieldValue('loginUsername', values.registerMail);
						sessionStorage.setItem('firstTime', 'true');
						setSingUpStatus(false);
						setSignInPassword(false);
					}
				})
				.catch((err) => {
					setIsLoading(false);
					//console.log(err.message);
				});
		},
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const handleContinue = () => {
		setIsLoading(true);
		const request = new XMLHttpRequest();
		request.open(
			'GET',
			`https://backend.aiosbot.com:8444/mailExists?mail=${formik.values.loginUsername}`,
			false,
		);
		request.send(null);
		if (request.status == 200) {
			setDbUsername(JSON.parse(request.response).username);
			setIsLoading(false);
			setSignInPassword(true);
		} else {
			setIsLoading(false);
			formik.setFieldError('loginUsername', 'No such user found in the system.');
		}
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': true })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo width={200} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(false);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(true);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='registerMail'
													isFloating
													label='Your email'>
													<Input
														autoComplete='mail'
														value={registerFormik.values.registerMail}
														isTouched={
															registerFormik.touched.registerMail
														}
														invalidFeedback={
															registerFormik.errors.registerMail
														}
														isValid={registerFormik.isValid}
														onChange={registerFormik.handleChange}
														onBlur={registerFormik.handleBlur}
														onFocus={() => {
															registerFormik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='registerUsername'
													isFloating
													label='Username'>
													<Input
														autoComplete='username'
														value={
															registerFormik.values.registerUsername
														}
														isTouched={
															registerFormik.touched.registerUsername
														}
														invalidFeedback={
															registerFormik.errors.registerUsername
														}
														isValid={registerFormik.isValid}
														onChange={registerFormik.handleChange}
														onBlur={registerFormik.handleBlur}
														onFocus={() => {
															registerFormik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='registerPassword'
													isFloating
													label='Password'>
													<Input
														type='password'
														autoComplete='current-password'
														value={
															registerFormik.values.registerPassword
														}
														isTouched={
															registerFormik.touched.registerPassword
														}
														invalidFeedback={
															registerFormik.errors.registerPassword
														}
														isValid={registerFormik.isValid}
														onChange={registerFormik.handleChange}
														onBlur={registerFormik.handleBlur}
														onFocus={() => {
															registerFormik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={registerFormik.handleSubmit}>
													Sign Up
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Your email'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='mail'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hi, {dbUsername}.
													</div>
												)}
												<FormGroup
													id='loginPassword'
													isFloating
													label='Password'
													className={classNames({
														'd-none': !signInPassword,
													})}>
													<Input
														type='password'
														autoComplete='current-password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={
															!formik.values.loginUsername ||
															!validateEmail(
																formik.values.loginUsername,
															)
														}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Continue
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={formik.handleSubmit}>
														Login
													</Button>
												)}
											</div>
										</>
									)}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
